import { useEffect } from 'react';
import { ReviewsIoStatus, reviewsIoCarouselStatusStore } from './stores';
import { useWritable } from 'lib/react-svelte/reactifyStores';

const ReviewsIoStylesHref = [
  'https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2022102413',
  'https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2022102413'
];

function loadReviewsIoStyles() {
  for (const href of ReviewsIoStylesHref) {
    const styles = document.createElement('link');
    styles.rel = 'stylesheet';
    styles.type = 'text/css';
    styles.media = 'screen';
    styles.href = href;
    document.getElementsByTagName('head')[0].appendChild(styles);
  }
}

export default function useProductReviewsIo() {
  const [reviewsStatus, setReviewsStatus] = useWritable(
    reviewsIoCarouselStatusStore
  );

  function loadReviewsIo() {
    const t = 'script';
    const g = document.createElement(t);
    const s = document.getElementsByTagName(t)[0];
    g.src =
      'https://widget.reviews.co.uk/carousel-inline-iframeless/dist.js?_t=2022102413';
    g.defer = true;
    g.async = true;
    if (s) {
      s.parentNode?.insertBefore(g, s);
      g.onload = function () {
        setReviewsStatus(ReviewsIoStatus.LOADED);
      };
    }
  }

  useEffect(() => {
    if (reviewsStatus === ReviewsIoStatus.LOADED) {
      return;
    }

    if (reviewsStatus === ReviewsIoStatus.INIT) {
      loadReviewsIo();
      loadReviewsIoStyles();
      setReviewsStatus(ReviewsIoStatus.LOADING);
    }

    const checkLoaded = setInterval(() => {
      if (typeof window.carouselInlineWidget !== 'undefined') {
        setReviewsStatus(ReviewsIoStatus.LOADED);
        clearInterval(checkLoaded);
      }
    }, 500);
  }, []);
}
