import React, { useEffect } from 'react';

import { Container } from 'components/atoms/layout/Container';
import useInlineCarouselLoader from './loaders/useInlineCarouselLoader';
import { Heading } from 'components/atoms/typography/Heading';
import Balancer from 'react-wrap-balancer';
import { makeClassNames } from 'lib/util';
import TrackSectionView from 'lib/events/TrackSectionView';
import { AppEventName } from 'lib/events/contracts';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import {
  ReviewsIoStatus,
  reviewsIoCarouselStatusStore
} from './loaders/stores';

const ReviewIoWidget: React.FC<PropsWithClassName> = ({ className }) => {
  useInlineCarouselLoader();
  const reviewsStatus = useReadable(reviewsIoCarouselStatusStore);

  useEffect(() => {
    if (reviewsStatus === ReviewsIoStatus.LOADED) {
      import('./widgets/inlineCarouselCompany').then(s => s.default());
    }
  }, [reviewsStatus]);

  if (reviewsStatus !== ReviewsIoStatus.LOADED) {
    return null;
  }

  return (
    <>
      <Container className={makeClassNames(className)}>
        <div className="flex justify-center text-center">
          <Heading level={2}>
            <Balancer>What They Say About Us</Balancer>
          </Heading>
        </div>

        <div id="reviewsio-carousel-widget"></div>
      </Container>
      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.SawMissionDriven
        })}
      />
    </>
  );
};

export default ReviewIoWidget;
